import React from "react";
import { Link } from "react-router-dom";
import Topbanner from "../../components/topbanner/Topbanner";
import { Footer } from "../../Home/Footer";
import { Header } from "../../Home/Header";

const TermsConditions = () => {
  return (
    <>
      <Header />
      {/* <HeaderNew/> */}

      <Topbanner heading="Terms of Service" height="45vh" />
      <section
        className="team  py-5"
        style={{ backgroundColor: "rgb(204, 204, 204)" }}
      >
        <div className="container">
          <div className="text-start">
            <strong>Your Acceptance of this Agreement</strong>
          </div>
          <br />
          <p className="text-start ">
            These terms of service are entered into by and between You and
            Natura Outdoor Education & Training Solutions Pvt Ltd, d/b/a Natura
            ("Company," "we," "our," or "us"). The following terms and
            conditions, together with any documents they expressly incorporate
            by reference (collectively "Terms of Service"), govern your access
            to and use of{" "}
            <Link to={"/"} className="text-black">
              https://natura.co.in
            </Link>{" "}
            , including any content, functionality, and services offered on or
            through{" "}
            <Link to={"/"} className="text-black">
              https://natura.co.in
            </Link>{" "}
            (the "Website"). Please read the Terms of Service carefully before
            you start to use the Website.
          </p>
          <p className="text-start ">
            By using the Website [or by clicking to accept or agree to the Terms
            of Service when this option is made available to you], you accept
            and agree to be bound and abide by these Terms of Service and our
            &nbsp;
            <Link to={"/privacy-policy"} className="text-black">
              Privacy Policy
            </Link>{" "}
            , incorporated herein by reference. If you do not want to agree to
            these Terms of Service, you must not access or use the Website.
          </p>
          <p className="text-start ">
            BY ACCESSING AND USING THIS WEBSITE, YOU: ACCEPT AND AGREE TO BE
            BOUND AND COMPLY WITH THESE TERMS OF SERVICE; YOU REPRESENT AND
            WARRANT THAT YOU ARE THE LEGAL AGE OF MAJORITY UNDER APPLICABLE LAW
            TO FORM A BINDING CONTRACT WITH US; AND, YOU AGREE IF YOU ACCESS THE
            WEBSITE FROM A JURISDICTION WHERE IT IS NOT PERMITTED, YOU DO SO AT
            YOUR OWN RISK.
          </p>
          <hr />
          <div className="text-start">
            <strong>Updates to Terms of Service</strong>
          </div>
          <br />
          <p className="text-start">
            We may revise and update these Terms of Service from time to time in
            our sole discretion. All changes are effective immediately when we
            post them and apply to all access to and use of the Website
            thereafter. Continuing to use the Website following the posting of
            revised Terms of Service means that you accept and agree to the
            changes. You are expected to check this page each time you access
            this Website so you are aware of any changes, as they are binding on
            you.
          </p>

          <hr />
          <div className="text-start">
            <strong>Your Responsibilities</strong>
          </div>
          <br />
          <p className="text-start">
            You are required to ensure that all persons who access the Website
            are aware of this Agreement and comply with it. It is a condition of
            your use of the Website that all the information you provide on the
            Website is correct, current, and complete. YOU ARE SOLELY AND
            ENTIRELY RESPONSIBLE FOR YOUR USE OF THE WEBSITE AND YOUR COMPUTER,
            INTERNET AND DATA SECURITY.
          </p>

          <hr />
          <div className="text-start">
            <strong>Prohibited Activities</strong>
          </div>
          <br />
          <strong
            className="text-start"
            style={{
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            You may use the Website only for lawful purposes and in accordance
            with these Terms of Service. You agree not to use the Website:
          </strong>

          <ul className="mt-1">
            <li className="show-li-dot">
              In any way that violates any applicable federal, state, local or
              international law or regulation (including, without limitation,
              any laws regarding the exports of data software to and from the
              India or other countries).
            </li>
            <li className="show-li-dot">
              For the purpose of exploiting, harming, or attempting to exploit
              or harm minors in any way by exposing them to inappropriate
              content, asking for personally identifiable information or
              otherwise.
            </li>
            <li className="show-li-dot">
              To send, knowingly receive, upload, download, use, or re-use any
              material that does not comply with the Submission Standards set
              out in these Terms of Service.
            </li>
            <li className="show-li-dot">
              To transmit, or procure the sending of, any advertising or
              promotional material, including any "junk mail," "chain letter,"
              "spam," or any other similar solicitation.
            </li>
            <li className="show-li-dot">
              To impersonate or attempt to impersonate the Company, a Company
              employee, another user, or any other persona or entity (including,
              without limitation, by using email addresses associated with any
              of the foregoing).
            </li>
            <li className="show-li-dot">
              To engage in any other conduct that restricts or inhibits anyone's
              use or enjoyment of the website, or which as determined by us, may
              harm the Company or users of the website, or expose them to
              liability.
            </li>
          </ul>

          <strong
            className="text-start"
            style={{
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            Additionally, you agree not to:
          </strong>
          <ul className="mt-1">
            <li className="show-li-dot">
              Use the Website in any manner that could disable, overburden,
              damage, or impair the site or interfere with any other party's use
              of the Website, including their ability to engage in real-time
              activities through the Website.
            </li>
            <li className="show-li-dot">
              Use any robot, spider, or other automatic device, process, or
              means to access the Website for any purpose, including monitoring
              or copying any of the material on the Website.
            </li>
            <li className="show-li-dot">
              Use any manual process to monitor or copy any of the material on
              the Website, or for any other purpose not expressly authorized in
              these Terms of Service, without our prior written consent.
            </li>
            <li className="show-li-dot">
              Use any device, software, or routine that interferes with the
              proper working of the Website.
            </li>
            <li className="show-li-dot">
              Introduce any viruses, Trojan horses, worms, logic bombs, or other
              material that is malicious or technologically harmful.
            </li>
            <li className="show-li-dot">
              Attempt to gain unauthorized access to, interfere with, damage, or
              disrupt any parts of the Website, the server on which the Website
              is stored, or any server, computer, or database connected to the
              Website.
            </li>
            <li className="show-li-dot">
              Attack the Website via a denial-of-service attack or a distributed
              denial-of-service attack.
            </li>
            <li className="show-li-dot">
              Otherwise attempting to interfere with the proper working of the
              Website
            </li>
          </ul>
          <hr />
          <div className="text-start">
            <strong>Intellectual Property Rights</strong>
          </div>
          <br />
          <strong
            className="text-start"
            style={{
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            The Website and its entire contents, features, and functionality
            (including but not limited to all information, software, text,
            displays, images, video, and audio, and the design, selection, and
            arrangement thereof) are owned by the Company, its licensors, or
            other providers of such material and are protected by United States
            and international copyright, trademark, patent, trade secret, and
            other intellectual property or proprietary rights laws. These Terms
            of Service permit you to use the Website for your personal,
            non-commercial use only. You must not reproduce, distribute, modify,
            create derivative works of, publicly display, publicly perform,
            republish, download, store, or transmit any of the material on our
            Website, except as follows:
          </strong>

          <ul>
            <li className="show-li-dot">
              Your computer may temporarily store copies of such material in RAM
              incidental to your accessing and viewing those materials.
            </li>
            <li className="show-li-dot">
              You may store files that are automatically cached by your Web
              browser for display enhancement purposes.
            </li>
            <li className="show-li-dot">
              You may print or download one copy of a reasonable number of pages
              of the Website for your own personal, non-commercial use and not
              for further reproduction, publication or distribution.
            </li>
          </ul>
          <strong
            className="text-start"
            style={{
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            {" "}
            You must not:
          </strong>
          <ul>
            <li className="show-li-dot">
              Modify copies of any materials from this site.
            </li>
            <li className="show-li-dot">
              Delete or alter any of the copyright, trademark, or other
              proprietary rights notices from copies of materials from this
              site.
            </li>
          </ul>

          <p className="text-start">
            You must not access or use for any commercial purposes any part of
            the website or any services or materials available through the
            Website. <br />
            If you print, copy, modify, download, or otherwise use or provide
            any other person with access to any part of the Website in breach of
            the Terms of Service, your right to use the Website will stop
            immediately and you must, at our option, return or destroy any
            copies of the materials you have made. No right, title, or interest
            in or to the Website or any content on the Website is transferred to
            you, and all rights not expressly granted are reserved by the
            Company. Any use of the Website not expressly permitted by these
            Terms of Service is a breach of these Terms of Service and may
            violate copyright, trademark, and other laws.
          </p>
          <hr />
          <div className="text-start">
            <strong>Our Rights</strong>
          </div>
          <br />
          <strong
            className="text-start"
            style={{
              fontSize: "14px",
              fontWeight: "600",
            }}
          >
            {" "}
            We have the right, without provision of notice to:
          </strong>
          <ul>
            <li className="show-li-dot">
              Take appropriate legal action, including, without limitation,
              referral to or cooperation with law enforcement or regulatory
              authorities, or notifying the harmed party of any illegal or
              unauthorized use of the Website; and
            </li>
            <li className="show-li-dot">
              Terminate or suspend your access to all or part of the Website for
              any or no reason, including, without limitation, any violation of
              these Terms of Service.
            </li>
          </ul>

          <p className="text-start">
            YOU WAIVE AND HOLD HARMLESS COMPANY AND ITS PARENT, SUBSIDIARIES,
            AFFILIATES, AND THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
            AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES,
            SUPPLIERS, AND SUCCESSORS FROM ANY AND ALL CLAIMS RESULTING FROM ANY
            ACTION TAKEN BY THE COMPANY AND ANY OF THE FOREGOING PARTIES
            RELATING TO ANY, INVESTIGATIONS BY EITHER THE COMPANY OR BY LAW
            ENFORCEMENT AUTHORITIES
          </p>
          <hr />
          <div className="text-start">
            <strong>Disclaimers, Liability and Indemnification</strong>
          </div>
          <br />
          <p className="text-start">
            YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE WEBSITE, ITS CONTENT,
            AND ANY GOODS, DIGITAL PRODUCTS, SERVICES, INFORMATION OR ITEMS
            FOUND OR ATTAINED THROUGH THE WEBSITE IS AT YOUR OWN RISK. THE
            WEBSITE, ITS CONTENT, AND ANY GOODS, SERVICES, DIGITAL PRODUCTS,
            INFORMATION OR ITEMS FOUND OR ATTAINED THROUGH THE WEBSITE ARE
            PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY
            WARRANTIES OR CONDITIONS OF ANY KIND, EITHER EXPRESS OR IMPLIED
            INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
            NON-INFRINGEMENT. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT
            CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
            <br />
            YOU ACKNOWLEDGE AND AGREE THAT COMPANY OR ITS RESPECTIVE DIRECTORS,
            OFFICERS, EMPLOYEES, AGENTS, SERVICE PROVIDERS, CONTRACTORS,
            LICENSORS, LICENSEES, SUPPLIERS, OR SUCCESSORS MAKE NO WARRANTY,
            REPRESENTATION, OR ENDORSEMENT WITH RESPECT TO THE COMPLETENESS,
            SECURITY, RELIABILITY, SUITABILITY, ACCURACY, CURRENCY, OR
            AVAILABILITY OF THE WEBSITE OR ITS CONTENTS OR THAT ANY GOODS,
            SERVICES, DIGITAL PRODUCTS, INFORMATION OR ITEMS FOUND OR ATTAINED
            THROUGH THE WEBSITE WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR
            UNINTERRUPTED, THAT DEFECTS WILL BE CORRECTED, THAT OUR WEBSITE OR
            THE SERVER THAT MAKES IT AVAILABLE OR CONTENT ARE FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS OR DESTRUCTIVE CODE.
            <br />
            <b className="">How We Limit Our Liability to You</b>
            <br />
            EXCEPT WHERE SUCH EXCLUSIONS ARE PROHIBITED BY LAW, IN NO EVENT
            SHALL THE COMPANY NOR ITS RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
            AGENTS, SERVICE PROVIDERS, CONTRACTORS, LICENSORS, LICENSEES,
            SUPPLIERS, OR SUCCESSORS BE LIABLE UNDER THESE TERMS OF SERVICE TO
            YOU OR ANY THIRD-PARTY FOR ANY CONSEQUENTIAL, INDIRECT, INCIDENTAL,
            EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES WHATSOEVER, INCLUDING ANY
            DAMAGES FOR BUSINESS INTERRUPTION, LOSS OF USE, DATA, REVENUE OR
            PROFIT, COST OF CAPITAL, LOSS OF BUSINESS OPPORTUNITY, LOSS OF
            GOODWILL, WHETHER ARISING OUT OF BREACH OF CONTRACT, TORT (INCLUDING
            NEGLIGENCE), ANY OTHER THEORY OF LIABILITY, OR OTHERWISE, REGARDLESS
            OF WHETHER SUCH DAMAGES WERE FORESEEABLE AND WHETHER OR NOT THE
            COMPANY WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            <br />
            <b className="">Indemnification</b>
            <br />
            To the maximum extent permitted by applicable law, you agree to
            defend, indemnify, and hold harmless Company, its parent,
            subsidiaries, affiliates, and their respective directors, officers,
            employees, agents, service providers, contractors, licensors,
            suppliers, successors, and assigns from and against any claims,
            liabilities, damages, judgments, awards, losses, costs, expenses, or
            fees (including reasonable attorneys' fees) arising out of or
            relating to your breach of these Terms of Service or your use of the
            Website including, but not limited to, third-party sites and
            content, any use of the Website's content and services other than as
            expressly authorized in these Terms of Service or any use of any
            goods, digital products and information purchased from this Website.
            HER HARMFUL COMPONENTS OR DESTRUCTIVE CODE.
          </p>
          <hr />
          <div className="text-start">
            <strong>Privacy Policy</strong>
          </div>
          <br />
          <p className="text-start">
            Your provision of personal information through the Website is
            governed by our privacy policy located at{" "}
            <Link className="text-black" to={"/privacy-policy"}>
              https://natura.co.in/privacy-policy
            </Link>{" "}
            (the "Privacy Policy").
          </p>
          <hr />
          <div className="text-start">
            <strong>Governing Law</strong>
          </div>
          <br />
          <p className="text-start">
            The Website and these Terms of Service will be governed by and
            construed in accordance with the laws of India and any applicable
            federal laws applicable therein, without giving effect to any choice
            or conflict of law provision, principle, or rule and notwithstanding
            your domicile, residence, or physical location. Any action or
            proceeding arising out of or relating to this Website and/or under
            these Terms of Service will be instituted in the courts of the
            Country of India, and each party irrevocably submits to the
            exclusive jurisdiction of such courts in any such action or
            proceeding. You waive any and all objections to the exercise of
            jurisdiction over you by such courts and to the venue of such
            courts.
            <br />
            The parties agree that the United Nations Convention on Contracts
            for the International Sale of Goods will not govern these Terms of
            Service or the rights and obligations of the parties under these
            Terms of Service.
          </p>
          <hr />
          <div className="text-start">
            <strong>Severability</strong>
          </div>
          <br />
          <p className="text-start">
            If any provision of these Terms of Service is illegal or
            unenforceable under applicable law, the remainder of the provision
            will be amended to achieve as closely as possible the effect of the
            original term and all other provisions of these Terms of Service
            will continue in full force and effect.
          </p>
          <hr />
          <div className="text-start">
            <strong>Entire Terms of Service</strong>
          </div>
          <br />
          <p className="text-start">
          These Terms of Service constitute the entire and only Terms of Service between the parties in relation to its subject matter and replaces and extinguishes all prior or simultaneous Terms of Services, undertakings, arrangements, understandings or statements of any nature made by the parties or any of them whether oral or written (and, if written, whether or not in draft form) with respect to such subject matter. Each of the parties acknowledges that they are not relying on any statements, warranties or representations given or made by any of them in relation to the subject matter of these Terms of Service, save those expressly set out in these Terms of Service, and that they shall have no rights or remedies with respect to such subject matter otherwise than under these Terms of Service save to the extent that they arise out of the fraud or fraudulent misrepresentation of another party. No variation of these Terms of Service shall be effective unless it is in writing and signed by or on behalf of Company.

          </p>

        </div>
      </section>

      <Footer />
    </>
  );
};

export default TermsConditions;
